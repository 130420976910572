<template>
  <div class="banner">
    <div class="banner-width">
      <div >
       <Banner></Banner>
      </div>
      <div class="skill-form">
        <div class="skill-form-center">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">

          <!-- 标题 -->
          <el-form-item label="给您提供的服务起一个标题" prop="subject">
            <el-input v-model="ruleForm.subject" placeholder="要求简介清晰"></el-input>
          </el-form-item>
          <!-- 详细描述 -->
          <el-form-item label="请详细描述一下您的服务" prop="content">
            <el-input
            type="textarea"
            :rows="3"
            class="skill-description"
            v-model="ruleForm.content"
            maxlength="300"
            placeholder="介绍你服务包含哪些内容;最多可输入300个字符"
            show-word-limit
            ></el-input>
          </el-form-item>

          <div class="nextDiv" v-for="(item, index) in nextShowList" :key="index" >
            <!-- 技能 -->
            <el-form-item label="完成该工作需要什么技能" prop="skills" v-show="item.key==1&&item.is_show" >
              <span class="label-skill">输入最多5项最能描述您的项目的技能</span>
              <div class="skill-clas">
                <p class="skill-classification">可根据您选择得技能分类，推荐</p>

                <el-select v-model="ruleForm.cat_id" @change="updateSkill" placeholder="请选择技能分类">
                  <el-option-group
                    v-for="groupCat in categorys"
                    :key="groupCat.id"
                    :label="groupCat.name">
                    <el-option
                      v-for="cat in groupCat.children"
                      :key="cat.id"
                      :label="cat.name"
                      :value="cat.id">
                    </el-option>
                  </el-option-group>

                </el-select>

              </div>
              <div class="skill-select">
              <!-- 最多可以选5个技能 -->
              <el-select
                v-model="ruleForm.skills"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="点击输入技能"
                :remote-method="remoteMethod"
                :loading="loading"
                :multiple-limit="5"
                style="width: 720px">
                <el-option
                  v-for="item in skills"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              </div>
              <p class="skill-p">
                推荐技能：
                <span
                v-for="reskill in recommand_skills"
                :key="reskill.id">
                  <el-link @click="clickAppendSkill(reskill)" type="primary">{{reskill.name}} </el-link>
                </span>
              </p>
              <!-- 根据后台返回的数据 然后显示 -->
              <strong class="skill-Strong" v-show="false">最多选择5项技能</strong>
            </el-form-item>

            <!-- 收费方式 -->
            <el-form-item label="收费方式" prop="price" v-show="item.key==2&&item.is_show">
              <div class="skill-money">
                <div class="skill-money-left">

                  <el-button :style="hourBtnActive? activePayWay.style : uactivePayWay.style" @click="changeBtn( 'hour' )">
                    <img style="float:left;margin-right:1rem" :src="hourBtnActive? activePayWay.hourIcon : uactivePayWay.hourIcon" alt="">
                    <span style="float:left">按小时收费</span>
                  </el-button>

                  <el-button :style="fixedBtnActive? activePayWay.style : uactivePayWay.style" @click="changeBtn( 'fixed' )">
                    <img style="float:left;margin-right:1rem" :src="fixedBtnActive? activePayWay.fixedIcon : uactivePayWay.fixedIcon" alt="">
                    <span style="float:left">固定价格</span>
                  </el-button>

                </div>
                <div class="skill-money-right">
                  <div class="skill-money-right1" v-show="hourBtnActive" >
                    <div class="skill-money-right-center">
                      <div class="skill-money-right-center-top">
                        <p>根据小时费率雇用并按计费小时数付费，最适合正在进行的工作。</p>
                      </div>
                      <div class="skill-money-right-center-bottom">
                        <div class="skill-money-right-center-bottom1">
                          <label for="hourly">时薪<input type="number" v-model="ruleForm.price" name="hourly" id="hourly" class="bottom1-input"></label>
                          <i>￥</i> <b>元</b>
                        </div>
                        <div class="skill-money-right-center-bottom2">
                          <label for="day">交付周期
                            <input type="number" name="day" id="day">
                            <i>天</i>
                          </label>

                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="skill-money-right2" v-show ="fixedBtnActive">
                    <div class="skill-money-right-center">
                      <div class="skill-money-right-center-top">
                        <p>根据小时费率雇用并按计费小时数付费，最适合正在进行的工作。</p>
                      </div>
                      <div class="skill-money-right-center-bottom">
                        <div class="skill-money-right-center-bottom1">
                          <label for="hourly">一口价<input type="number" v-model="ruleForm.price" name="hourly" id="hourly" class="bottom1-input"></label>
                          <i>￥</i> <b>元</b>
                        </div>
                        <div class="skill-money-right-center-bottom2">
                          <label for="day">交付周期
                            <input type="number" name="day" id="day">
                            <i>天</i>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-form-item>

            <!-- 上传服务展示 -->

            <div v-show="item.key==2&&item.is_show">

              <el-card style="width: 160px;height:90px;padding:unset" v-show="ruleForm.thumb != ''">
                <el-image style="width:160px; height:90px; background:#f4f4f4" :src="ruleForm.thumb" ></el-image>
              </el-card>
              <el-form-item prop="thumb">
                <el-upload
                  :action="postUrl"
                  :headers="headers"
                  :multiple="true"
                  :file-list="ruleForm.more_thumbs"
                  :show-file-list="true"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  list-type="picture-card"
                  :limit=10>

                    <div style="width:136px;height:77px;background:#f4f4f4">
                      <i slot="default" class="el-icon-plus"></i>
                      <div slot="tip" style="font-size:12px;color:#b5b5b5">点击上传图片</div>
                    </div>

                    <div slot="file" slot-scope="{file}">
                        <img class="el-upload-list__item-thumbnail" style="width:136px;height:77px;" :src="file.url" alt="" >
                        <span class="el-upload-list__item-actions">
                          <span v-if="true" class="el-upload-list__item-delete" @click="handleRemovePic(file)">
                              <i class="el-icon-delete"></i>
                          </span>
                          <span v-if="true" class="el-upload-list__item-preview" @click="handleMainPreview(file)">
                            <el-tooltip class="item" effect="dark" content="设为主图" placement="bottom-end">
                              <i class="el-icon-place"></i>
                            </el-tooltip>
                          </span>
                        </span>
                    </div>
                </el-upload>
              </el-form-item>

              <p class="skill-hint">
                <span>提示</span>
                <ul>
                  <li>1、第一张默认为封面，封面是成单的核心；</li>
                  <li>2、建议比例16:9；</li>
                  <li>3、支持JPG、GIF格式，小于5M；</li>
                  <li>4、最多可上传10张。</li>
                </ul>
              </p>

              <div class="skill-button">
                <el-button type="button" class="skill-button-preview" @click="toPreview()">预览</el-button>
                <el-button type="button" class="skill-button-issue" @click="toPublic">立即发布</el-button>
              </div>
            </div>
          </div>

          <el-button class="nextStep" v-show="nextStepBtn" size="small" @click="nextStep()"> 下一步 </el-button>
        </el-form>


        <el-dialog
            :title="previewData.subject"
            :visible.sync="dialogVisible"
            :before-close="handleClose"
            width="800px"
            center
            class="dia-font">
          <p>
            <ul>
              <li>在此时间之前，自由职业者可向您发出求职申请。时间最大不能超2022年2与3日申请。在此时间之前，自由职业者可向您发出求职申</li>
              <li>请。时间最大不能超过2022年2与3日申请。在此时间之前，自由职业者可向您发出求职申请。时间最大不能超过2022年2与3日申请。</li>
              <li>在此时间之前，自由职业者可向您发出求职申请。时间最大不能超过2022年2与3日申请。在此时间之前，自由职业者可向您发出求职申</li>
              <li>请。时间最大不能超过2022年2与3日申请。</li>
            </ul>
          </p>
          <div class="check-img-font">
            <div class="check-img-border">
              <img :src="ruleForm.thumb" alt="">
            </div>
            <div class="check-font-border">
              <ul>
                <li>
                  <strong>分类</strong>
                  <span> {{ previewData.cat_name }} </span>
                </li>
                <li>
                  <strong>技能</strong>
                  <span style="margin-right:10px;" v-for="(skill, key ) in previewData.skills" :key="key"> {{ skill }} </span>
                </li>
                <li>
                  <strong>时薪</strong>
                  <span>￥ {{ previewData.price }} 元</span>
                </li>
                <li>
                  <strong>交付工期</strong>
                  <span> {{ previewData.cycle }} 天</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="check-img-box">
            <div class="check-img-boxs">

              <div class="check-img-boxs-border" v-for="(img, k) in ruleForm.more_thumbs" :key="k">
                <img :src="img.url" alt="" />
              </div>

            </div>
          </div>
            <span slot="footer" class="dialog-footer">
             <el-button @click="dialogVisible = false" class="back-button">返回修改</el-button>
             <el-button type="primary" @click="toPublic" class="go-button">立即发布</el-button>
            </span>
         </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecommandskills, getSkillsCategory, getSkills, updateService, getServiceInfo } from '@/api/service'
import Banner from "@/components/Banner"
export default {
  components:{
    Banner
  },
  data() {
    return {
      /** lipeng */
      serviceId: 0,
      nextStepBtn: false,
      nextShowList: [
        { key: 1, is_show: true },
        { key: 2, is_show: true },
      ],
      // 推荐技能
      categorys: [],
      skills: [],
      recommand_skills: [],
      // 切换收费模式
      hourBtnActive: true,
      fixedBtnActive: false,
      activePayWay: {
        style: {
          'background': '#00a2e6',
          'color': '#fff',
          'height': '36px',
          'width': '100%',
          'padding': '5px 14px',
          'line-height': 2,
          'margin-left': 'unset'
        },
        hourIcon: require( '@/icons/svg/task/时薪-反白24X24.svg' ),
        fixedIcon: require( '@/icons/svg/task/固定薪酬-反白24X24.svg' ),
      },
      uactivePayWay: {
        style: {
          'height': '36px',
          'width': '100%',
          'padding': '5px 14px',
          'line-height': 2,
          'margin-left': 'unset'
        },
        hourIcon: require( '@/icons/svg/task/小时-未选中48X48.svg' ),
        fixedIcon: require( '@/icons/svg/task/固定薪酬-未选中48X48.svg' )
      },
      // 图片上传部分
      postUrl: '/api/upload',
      headers: { Authorization: `Bearer ${this.$root.current_token}` },
      // 表单验证部分
      ruleForm: {
        subject: "",
        content: '',
        pay_way: 2,
        price: '',
        skills: [],
        thumb: '',
        more_thumbs: []
      },
      rules: {
        subject: [
          { required: true, message: "请输入服务标题", trigger: "blur" },
          { min: 3, max: 15, message: "长度在 3 到 15 个字符", trigger: "blur" },
        ],
        content:[
          {
            required:true, message:"请描述您的服务",trigger:"blur"
          }
        ],
        skills: [
          {
            required:true, message:"请选择您的技能",trigger:"blur"
          }
        ],
        pay_way: [
          {
            required:true, message:"请选择收费方式",trigger:"blur"
          }
        ],
        price: [
          {
            required:true, message:"请输入您的时薪/价格",trigger:"blur"
          }
        ],
        thumb: [
          {
            required:true, message:"请上传商品图片",trigger:"change"
          }
        ],
      },
      // 预览的dialog
      dialogVisible: false,
      previewData: {
        subject: '',
        cat_name: '',
        skills: [],
        thumb: '',
        more_thumbs: [],
        price: 0,
        cycle: 0,
      },
      /** lipeng end */

      options: [],
      list: [],
      loading: false,
    };
  },
  mounted() {
    this.serviceId = this.$route.params.id
    this.loadAllSkillCategory()
    this.loadInfo()
  },
  methods: {
    handleClose(done) {
      this.dialogVisible = false
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    beforeAvatarUpload(file) {
      const isJPGOrGIF = ["image/jpeg", "image/gif"].indexOf(file.type) >= 0;
      const isLt5M = file.size / 1024 / 1024 < 5;

      if (!isJPGOrGIF) {
        this.$notify({
          position: 'top-left',
          type: 'error',
          title: '通知',
          message: "上传头像图片只能是 JPG 格式 GIF格式!"
        })
      }
      if (!isLt5M) {
        this.$notify({
          position: 'top-left',
          type: 'error',
          title: '通知',
          message: "上传头像图片大小不能超过 5MB!"
        })
      }
      return isJPGOrGIF && isLt5M;
    },

    toPublic(){
      this.dialogVisible = false;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let postData = {
            id: this.ruleForm.id,
            subject: this.ruleForm.subject,
            content: this.ruleForm.content,
            pay_way: this.ruleForm.pay_way,
            price: this.ruleForm.price,
            skills: this.ruleForm.skills,
            thumb: this.ruleForm.thumb,
            more_thumbs:[]
          }
          this.ruleForm.more_thumbs.forEach(item => {
            postData.more_thumbs.push( item.url )
          })

          this.update( postData )
        } else {
          return false;
        }
      });
    },
    /** lipeng */
    // 下一步
    nextStep(){
      try {
        this.nextShowList.forEach(item => {
          if( item.is_show === false ){

            switch( item.key ){
              case 1:
                let data = {
                  subject: this.ruleForm.subject,
                  content: this.ruleForm.content,
                }
                this.searchSkills( data )
                break;
              case this.nextShowList.length:
                this.nextStepBtn = false // 最后一步删除 按钮元素
                break;
            }

            item.is_show = true
            throw new Error("ending");//跳出循环
          }
        })
      } catch (error) {
        if(error.message == "ending"){
        }
      }
    },
    // 点击追加推荐技能
    clickAppendSkill( skill ){
      var canAppend = true;
      this.skills.forEach( ele => {
        if( ele.id == skill.id ){
          canAppend = false
        }
      })
      if( canAppend ){
        this.skills.push( skill )
        this.ruleForm.skills.push( skill.id )
        this.previewData.skills.push( skill.name )
      }

    },
    // 异步检索技能方法
    remoteMethod(query) {

    },
    // 切换收费方式
    changeBtn( type ){
      switch( type ){
        case 'hour':
          this.hourBtnActive = true
          this.fixedBtnActive = false
          this.ruleForm.pay_way = 2
          break;
        case 'fixed':
          this.hourBtnActive = false
          this.fixedBtnActive = true
          this.ruleForm.pay_way = 1
          break;
      }
    },
    // 删除图片
    handleRemovePic( file ){
      this.ruleForm.more_thumbs.splice( file , 1)
    },
    handleMainPreview( file ){
      console.log(file)
      this.ruleForm.thumb = file.url
    },
    handleAvatarSuccess(res, file) {
      this.ruleForm.more_thumbs.push( res.data[0] )
    },
    // 预览
    toPreview(){
      console.log( this.ruleForm )
      this.previewData.subject = this.ruleForm.subject
      this.previewData.price = this.ruleForm.price
      this.previewData.more_thumbs = this.ruleForm.more_thumbs

      this.dialogVisible = true
    },
    async searchSkills( data ){
      const res = await getRecommandskills( data, this.$root.current_token)
      if( res.statusCode == 200 ){
        this.ruleForm.cat_id = res.data.category.name
        this.skills = res.data.skills
        this.recommand_skills = res.data.recommand_skills.list
      }
    },
    async loadAllSkillCategory(){
      const res = await getSkillsCategory()
      if( res.statusCode == 200 ){
        this.categorys = res.data
      }
    },
    async updateSkill(catId){
      let obj = {};
      this.categorys.forEach((item)=>{
          var ress = item.children.find(element => {
            return element.id === catId;
          });
          if( ress != 'undefined' ){
            obj = ress
          }
      });

      this.previewData.cat_name = obj.name
      const res = await getSkills( {cat_id: catId} )
      if( res.statusCode == 200 ){
        this.skills = res.data.list
        this.recommand_skills = res.data.list
      }
    },
    async update( data ){
      const res = await updateService( data, this.$root.current_token )
      if( res.statusCode == 200 ){
        this.$message.success('修改成功')
        this.$router.push('/service/result/'+this.serviceId)
      }
    },
    async loadInfo(){
        const res = await getServiceInfo({id: this.serviceId})
        if( res.statusCode == 200 ){
            res.data.more_thumbs = res.data.more_thumb_array.map(item => {
                return {
                    name: item,
                    url: item
                }
            });
            this.skills = res.data.skills
            this.previewData.skills = res.data.skills.map(item => {
                return item.name
            })
            res.data.cat_ids.forEach( item => {
                res.data.cat_id = item
            })
            res.data.category_names.forEach( item => {
                this.previewData.cat_name = item
            })
            res.data.skills = res.data.skills_ids
            this.ruleForm = res.data
        }
    }
  },
};
</script>

<style lang="less" scoped>
/** lipeng */
/deep/.nextDiv{
  .el-form-item__content{
    margin-bottom: 1rem;
  }
}
.el-button:hover{
  color: #575757;
}
.nextStep{
  background: #00a2e6;
  color: #fff;
  margin-top: 1rem;
  :hover{
    color: #fff;
  }
}
/deep/.el-upload-list__item{
  width: 136px;
  float: left;
  margin-right: 1rem;
  margin-top: 10px;
  height: 77px;
}
/deep/.el-icon-plus {
  border: 1px solid #00a2e6;
  border-radius: 50%;
  color: #00a2e6;
}
/deep/.el-upload--picture-card i{
  font-size: 14px;
}
/deep/.el-upload--picture-card{
  width: 136px;
  height: 77px;
  border: 1px #c0ccda;
  line-height: unset;
  margin-top: 10px;
}
/deep/.el-card__body{
  padding: unset;
}
/** lipeng end*/

.banner {
  background-color: #f8f8f8;
}
.banner-width {
  width: 100%;
  margin-top: -20px;
  .banner-img-font {
    background-image: url(~@/assets/banner/底图.jpg);
    width: 100%;
    height: 400px;
    background-size: cover;
    p {
      position: relative;
      width: 800px;
      margin: 0 auto;
      top: 60px;
      color: #fff;
      strong {
        font-size: 24px;
        display: block;
        margin-bottom: 23px;
      }
      li {
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }
  /* 技能表单 */
  .skill-form {
    width: 800px;
    background: #ffffff;
    margin: 0 auto;
    margin-top: -175px;
    min-height: 350px;
    .skill-form-center {
      width: 720px;
      margin: 0 auto;
      height: 100%;
      position: relative;
      top: 20px;
    }
    .skill-description {
      width: 720px;
      font-size: 12px;
      /deep/.el-textarea__inner {
        border-radius: 0;
      }
    }
    .label-skill {
      font-size: 12px;
      color: #575757;
    }
    .skill-clas {
      display: flex;
      margin-bottom: 10px;
      .skill-classification {
        height: 36px;
        margin-right: 10px;
      }
    }
    .skill-Strong {
      display: block;
      line-height: 1;
      margin-top: 10px;
      color: #e4007f;
      font-size: 12px;
    }
    .skill-p {
      line-height: 1;
      margin-top: 10px;
      font-size: 14px;
      span {
        font-size: 12px;
        color: #575757;
        margin-right: 10px;
      }
    }
    .skill-select {
      width: 720px;
    }
    .skill-money {
      display: flex;
      width: 720px;
      img {
        width: 24px;
        height: 24px;
      }
      .skill-money-left {
        .skill-money-left1,
        .skill-money-left2 {
          display: flex;
          width: 150px;
          height: 36px;
          border: 1px solid #b5b5b5;
          cursor: pointer;
          img {
            margin: 6px 15px 6px 15px;
          }
          span {
            font-size: 14px;
            color: #575757;
          }
        }
      }
      .skill-money-left1 {
        text-align: center;
        margin-bottom: 10px;
        background-color: #00a2e6;
        .skill-money-left12 {
          span {
            color: #fff;
          }
        }
      }
      .skill-money-right {
        margin-left: 20px;
        width: 550px;
        .skill-money-right1 {
          width: 550px;
          height: 82px;
          border: 1px solid #b5b5b5;
          .skill-money-right-center {
            width: 530px;
            margin: 0 auto;
          }
          .skill-money-right-center-top {
            height: 34px;
            border-bottom: 1px solid #b5b5b5;
            p {
              font-size: 12px;
              color: #575757;
            }
          }
          .skill-money-right-center-bottom {
            line-height: 47px;
            display: flex;
            align-items: center;
            input {
              width: 120px;
              height: 28px;
              margin-left: 10px;
              text-indent: 20px;
            }
            .skill-money-right-center-bottom1 {
              height: 47px;
              i,
              b {
                color: #575757;
                font-size: 12px;
              }
              i {
                position: relative;
                left: -115px;
              }
              b {
                position: relative;
                left: -35px;
              }
            }
            .skill-money-right-center-bottom2 {
              i {
                font-size: 12px;
                color: #575757;
                position: relative;
                left: -18px;
              }
            }
          }
        }
        .skill-money-right2 {
          width: 550px;
          height: 82px;
          border: 1px solid #b5b5b5;
          .skill-money-right-center {
            width: 530px;
            margin: 0 auto;
          }
          .skill-money-right-center-top {
            height: 34px;
            border-bottom: 1px solid #b5b5b5;
            p {
              font-size: 12px;
              color: #575757;
            }
          }
          .skill-money-right-center-bottom {
            line-height: 47px;
            display: flex;
            align-items: center;
            input {
              width: 120px;
              height: 28px;
              margin-left: 10px;
              text-indent: 10px;
            }
            .skill-money-right-center-bottom1 {
              height: 47px;
              i,
              b {
                color: #575757;
                font-size: 12px;
              }
              i {
                position: relative;
                left: -115px;
              }
              b {
                position: relative;
                left: -35px;
              }
            }
            .skill-money-right-center-bottom2 {
              i {
                font-size: 12px;
                color: #575757;
                position: relative;
                left: -18px;
              }
            }
          }
        }
      }
    }

    .skill-hint {
      font-size: 12px;
      span {
        display: block;
        color: #e4007f;
        margin-bottom: 10px;
      }
      ul {
        li {
          color: #474747;
          margin-bottom: 10px;
        }
      }
    }
    .skill-button {
      width: 440px;
      height: 136px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      button {
        width: 200px;
        height: 36px;
        background-color: #00a2e6;
        cursor: pointer;
        color: #fff;
        font-size: 18px;
      }
      .skill-button-preview {
        background-color: #f4f4f4;
        color: #575757;
        margin-right: 40px;
        text-align: center;
        border-radius: 0;
        /deep/ span{
          position: relative;
          top: -4px;
        }
      }
    }
  }
}
.el-form-item {
  margin-bottom: 10px;
}
/deep/.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  color: green;
}
/deep/.el-input__inner {
  border-radius: 0;
}
/deep/.el-form-item__label {
  font-size: 18px;
}
/deep/.el-textarea__inner {
  //el_input中的隐藏属性
  resize: none; //主要是这个样式
}

/deep/.el-textarea textarea {
  padding-bottom: 30px;
}
/deep/.el-textarea .el-input__count {
  height: 12px;
  line-height: 12px;
}
.dia-font{
  h2{
      width: 720px;
      margin-top: 40px;
      font-size: 18px;
      margin-bottom: 24px;
    }
    p{
      margin-bottom: 22px;
      ul{
        li{
          font-size: 12px;
          color: #474747;
          margin-bottom: 12px;
        }
        &>li:last-of-type{
          margin-bottom: 0;
        }
      }
    }
     .check-img-font{
    height: 171px;
    display: flex;
    margin-bottom: 10px;
    .check-img-border{
      width: 300px;
      height: 171px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .check-font-border{
      margin-left: 20px;
      font-size: 14px;
      ul{
        li{
          display: flex;
          line-height: 33px;
          width: 400px;
          border-bottom: 1px solid #b5b5b5;
          strong{
            margin-left: 20px;
            width: 58px;
            margin-right: 25px;
            color: #000;
          }
          span{
            color: #575757;
          }
        }
      }
    }
  }
    .check-font-border{
      margin-left: 20px;
      font-size: 14px;
      ul{
        li{
          display: flex;
          line-height: 33px;
          width: 400px;
          border-bottom: 1px solid #b5b5b5;
          strong{
            margin-left: 20px;
            width: 58px;
            margin-right: 25px;
            color: #000;
          }
          span{
            color: #575757;
          }
        }
      }
    }
    .check-img-box{
    width: 720px;
    margin-bottom: 40px;
    .check-img-boxs{
      width: 740px;
      display: flex;
      flex-wrap: wrap;
      .check-img-boxs-border{
        width: 136px;
        height: 77px;
        margin-right: 10px;
        margin-bottom: 10px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      &>.check-img-boxs-border:last-of-type{
        margin-bottom: 0;
      }
    }
  }
  .dialog-footer{
    position: relative;
    top: -40px;
    display: flex;
    width: 440px;
    margin: 0 auto;
    line-height: 36px;
    button{
      width: 200px;
      height: 36px;
      font-size: 18px;
      border-radius: 0;
      margin-right: 30px;
    }
    /deep/.el-button{
      span{
        position: relative;
        top: -4px;
      }
    }
  }
}
/deep/.el-dialog__body{
      width: 720px;
      margin: 0 auto;
}
</style>
